import React from "react"
import { string } from "fast-web-kit"
import { Link } from "react-router-dom"
import { api, setPageTitle, socketURL, toast } from "../../helpers"
import { ApplicationContext } from "../../context"

const CardList = React.memo(() => {

    const { application } = React.useContext(ApplicationContext)

    React.useEffect(() => {
        setPageTitle("cards")
        onMount()
        return () => application.unMount()
        // eslint-disable-next-line
    }, [])

    async function onMount() {
        try {

            const joinForeignKeys = false
            const select = JSON.stringify({
                title: 1,
                image: 1,
                lastName: 1,
                firstName: 1,
                folderName: 1,
                qr: 1,
                "contacts.phoneNumber": 1,
            })
            const condition = JSON.stringify({})
            const sort = JSON.stringify({ firstName: 1, lastName: 1 })
            const parameters = `schema=card&condition=${condition}&select=${select}&joinForeignKeys=${joinForeignKeys}&sort=${sort}`
            const options = {
                parameters,
                loading: true,
                method: "GET",
                disabled: false,
                route: api + "list-all"
            }
            const response = await application.readOrDelete(options)

            if (response.success)
                application.dispatch({ cards: response.message })
            else
                toast(response.message)

        } catch (error) {
            toast(error.message)
        }
    }

    const deleteCard = async (_id) => {
        try {
            const condition = JSON.stringify({ _id })
            const parameters = `schema=card&condition=${condition}`
            const options = {
                parameters,
                loading: true,
                disabled: false,
                method: "DELETE",
                route: api + "delete"
            }
            const response = await application.readOrDelete(options)

            if (response.success) {
                application.dispatch({ cards: application.state.cards.filter((card) => card._id !== _id) })
                toast(`card has been deleted`)
            }
            else
                toast(response.message)

        } catch (error) {
            toast(error.message)
        }
    }

    const renderCards = React.useCallback(() => {
        try {

            return application.state.cards.map((card, index) => (
                <tr key={card._id}>
                    <td data-label={"#"}>{index + 1}</td>
                    <td data-label="name" style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <img src={`${socketURL}/uploads/${card.folderName}/${card.image}`} alt={card.firstName}
                            style={{
                                borderRadius: "100%",
                                marginRight: 10,
                                width: 40,
                                height: 40,
                            }}
                        />
                        <span className="bold">
                            {string.capitalize(card.firstName)}&nbsp;{string.capitalize(card.lastName)}
                        </span>
                    </td>
                    <td data-label="title">{string.toTitleCase(card.title)}</td>
                    <td data-label="phone number" className="center">
                        <a href={`tel:+${card.contacts.phoneNumber}`}>
                            +{card.contacts.phoneNumber}
                        </a>
                    </td>
                    <td>
                        <div className="action-button">
                            <Link to={{
                                pathname: "/card/form",
                                state: { card: card._id }
                            }} className="button primary" data-tooltip="Edit card">
                                <i className="material-icons">edit_note</i>
                            </Link>
                            <Link to={{pathname: `/card/${card.qr}`}} className="button info" data-tooltip="view card">
                                <i className="material-icons">visibility</i>
                            </Link>
                            <Link to="#" className="button error" data-tooltip="Delete card" onClick={() => deleteCard(card._id)}>
                                <i className="material-icons">delete</i>
                            </Link>
                        </div>
                    </td>
                </tr>
            ))

        } catch (error) {
            toast(error.message)
        }
        // eslint-disable-next-line
    }, [application.state.cards])

    return (
        <>
            <div className="row">
                <div className="col s12">
                    <div className="card list">
                        <div className="card-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>name</th>
                                        <th>title</th>
                                        <th className="center">phone number</th>
                                        <th className="center">action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderCards()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default CardList