import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { ApplicationContext } from "../../context";

const QRCodeComponent = ({ qrValue }) => {

    const { application } = React.useContext(ApplicationContext)
    const qrRef = useRef();

    // Function to download QR Code as an image
    const downloadQRCode = () => {
        const svg = qrRef.current.querySelector("svg");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Setting canvas size
        canvas.width = svg.clientWidth;
        canvas.height = svg.clientHeight;

        // Create an image from the SVG
        const img = new Image();
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);

            // Create a PNG from canvas and trigger download
            const pngUrl = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = application.state.qr;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };
        img.src = url;
    };

    return (
        <div className="row" style={{ marginBottom: 100}}>
            <div className="col s12 center">
                <div ref={qrRef} style={{padding: 20,}}>
                    <QRCode value={`https://i-card.co/card/${qrValue}`} size={150} />
                </div>
                <button style={{ backgroundColor: application.state.color}} onClick={downloadQRCode}>Download QR Code</button>
            </div>
        </div>
    );
};

export default QRCodeComponent;
