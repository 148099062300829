import React from "react"

const Loader = React.memo(() => (
    <div className="loader-container">
        <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
))

export default Loader