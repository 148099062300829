// dependencies
import React from "react"
import NavBar from "./components/navbar"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "./pages/user/login"
import { ApplicationContext } from "./context"
import CardForm from "./pages/card/card-form"
import Loader from "./components/loader"
import CardList from "./pages/card/list"
import CardView from "./pages/card/view"

const App = React.memo(() => {

  const { application } = React.useContext(ApplicationContext)

  // component mounting
  React.useEffect(() => {
    application.retrieveUserAndAuthenticate()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Router>
        {
          application.state.loading
            ? <Loader />
            : null
        }
        {
          application.state.authenticated
            ?
            <NavBar />
            : null
        }
        <main id="main">
          {
            application.state.authenticated
              ?
              <Switch>
                <Route path="/card/form" component={CardForm} />
                <Route path="/card/list" component={CardList} />
                <Route path="/card/:user" component={CardView} />
                <Route path="*" component={CardForm} />
              </Switch>
              :
              <Switch>
                <Route path="/card/:user" component={CardView} />
                <Route path="*" component={Login} />
              </Switch>
          }
        </main>
        <footer style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          padding: '10px 0',
          color: '#fff',
        }}>
          <a href="tel:+255657658515">Developed and Mantained by Digital Edge &copy;{new Date().getFullYear()}</a>
        </footer>
      </Router>
    </>
  )
})

// exporting application
export default App