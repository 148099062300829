import React from "react"
import reducer from "../hooks/reducer"
import initialState from "../hooks/initial-state"
import Application from "../helpers/application"
import { ApplicationContext } from "../context"

export const ApplicationProvider = React.memo(({ children }) => {

    // state management
    const [state, dispatch] = React.useReducer(reducer, initialState)

    // application helpers
    const application = new Application(state, dispatch)

    // returning component
    return (
        <ApplicationContext.Provider value={{ state, dispatch, application }}>
            {children}
        </ApplicationContext.Provider>
    )
    
})