// dependencies
import React from "react"
import { applicationName } from "../helpers"
import { ApplicationContext } from "../context"

// navigation bar memorized function component
const NavBar = React.memo(() => {

    // application context
    const { application } = React.useContext(ApplicationContext)

    // returning component
    return (
        <div className="breadcrumb">
            <div className="section">
                <i className="material-icons">badge</i>
                <span className="title">{applicationName}</span>
            </div>
            <div className="right-section">
                <a href="/card/form" className="section">
                    <span className="title">new</span>
                </a>
                <a href="/card/list" className="section">
                    <span className="title">cards</span>
                </a>
                <div className="section" onClick={() => application.authenticate("logout")}>
                    <span className="title">Logout</span>
                </div>
            </div>
        </div>
    )
})

export default NavBar