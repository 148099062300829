import React from "react"
import { string } from "fast-web-kit"

export const Input = React.memo((props) => {

    return (
        <>
            <label htmlFor={props.name}>
                {string.toTitleCase(props.label)}
            </label>
            <input
                id={props.name}
                name={props.name}
                type={props.type}
                autoComplete="off"
                value={props.value}
                accept={props.accept}
                onChange={props.onChange}
                disabled={props.disabled}
                readOnly={props.readOnly}
                data-length={props.dataLength}
                placeholder={string.capitalize(props.placeholder)}
            />
            <span className="helper-text red-text">
                {string.capitalize(props.error)}
            </span>
        </>
    )
})

export const Button = React.memo((props) => {
    return (
        <button
            className={`btn ${props.disabled || props.loading ? "disabled" : ""}`}
            type={props.type || "submit"}
            disabled={props.loading || props.disabled}
        >
            {string.capitalize(props.loading ? "loading" : props.disabled ? "error" : props.title)}
        </button>
    )
})

export const Textarea = React.memo((props) => (
    <>
        <label htmlFor={props.name}>{string.toTitleCase(props.label)}</label>
        <textarea
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            readOnly={props.readOnly}
            disabled={props.disabled}
            placeholder={string.capitalize(props.placeholder ? props.placeholder : "")}
        />
        <span className="helper-text">{string.capitalize(props.error)}</span>
    </>
))