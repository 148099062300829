// dependencies
import React from "react"
import { Link } from "react-router-dom"
import { CardTitle } from "../../components/card"
import { ApplicationContext } from "../../context"
import { api, serverURL, setPageTitle, toast } from "../../helpers"
import { Button, Input, Textarea } from "../../components/input"
import pluralize from "pluralize"
import { array, object, string } from "fast-web-kit"
import CardPreview from "./preview"

// card form memorized function component
const CardForm = React.memo((props) => {

    // application context
    const { application } = React.useContext(ApplicationContext)

    // component mounting
    React.useEffect(() => {
        onMount()
        return () => application.unMount()
        // eslint-disable-next-line
    }, [])

    // creating user uploading folder name
    React.useEffect(() => {
        if (!application.state.edit) {
            const folderName = `${application.state.firstName.trim().toLowerCase()}_${new Date().getTime()}`
            application.dispatch({ folderName })
        }
        // eslint-disable-next-line
    }, [application.state.firstName])

    React.useEffect(() => {
        if (!application.state.edit) {
            let fullName = `${application.state.firstName} ${application.state.lastName}`
            fullName = string.removeExtraWhitespace(fullName)
            fullName = string.toSnakeCase(fullName)
            application.dispatch({ qr: fullName })
        }
    }, [application.state.firstName, application.state.lastName])

    // checking if is updating time
    async function onMount() {
        try {

            setPageTitle("new card")
            if (props.location.state) {

                const { card } = props.location.state

                if (card) {
                    const select = JSON.stringify({ createdAt: 0, updatedAt: 0, __v: 0 })
                    const joinForeignKeys = false
                    const condition = JSON.stringify({ _id: card })
                    const parameters = `schema=card&condition=${condition}&select=${select}&joinForeignKeys=${joinForeignKeys}`
                    const options = {
                        parameters,
                        method: "GET",
                        loading: true,
                        disabled: false,
                        route: api + "read"
                    }

                    const response = await application.readOrDelete(options)

                    if (response.success) {
                        setPageTitle("edit card")
                        const card = response.message
                        application.dispatch({
                            qr: card?.qr,
                            edit: true,
                            id: card._id,
                            logo: card.logo,
                            image: card.image,
                            title: card.title,
                            color: card.color,
                            oldLogo: card.logo,
                            abouts: card.abouts,
                            oldImage: card.image,
                            services: card.services,
                            products: card.products,
                            lastName: card.lastName,
                            firstName: card.firstName,
                            email: card.contacts?.email,
                            vcard: card.contacts?.vcard,
                            folderName: card.folderName,
                            region: card.address.region,
                            oldVcard: card.contacts?.vcard,
                            country: card.address.country,
                            location: card.address.location,
                            whatsapp: card.contacts?.whatsapp,
                            facebook: card.contacts?.facebook,
                            linkedin: card.contacts?.linkedin,
                            instagram: card.contacts?.instagram,
                            phoneNumber: card.contacts?.phoneNumber
                        })

                    }
                }
            }

        } catch (error) {
            toast(error.message)
        }
    }

    // adding new form ie: service, about or product
    const addForm = (type) => {
        try {

            let common = { name: "" }
            common = type === "about" ? { ...common, description: "", } : { ...common, image: null, oldImage: null }
            const collection = pluralize(type)
            application.dispatch({ [collection]: array.addElement(application.state[collection], common) })

        } catch (error) {
            toast(error.message)
        }
    }

    // handling product, service or about input change
    const handleFormChange = (event, index, type) => {
        try {

            const { value, name } = event.target
            const collection = pluralize(type)
            const newDataArray = [...application.state[collection]]
            newDataArray[index][name] = value
            application.dispatch({ [collection]: newDataArray })

        } catch (error) {
            toast(error.message)
        }
    }

    // removing form ie: service, about or product
    const removeForm = (index, type) => {
        try {
            const collection = pluralize(type)
            application.state[collection].splice(index, 1)
        } catch (error) {
            toast(error.message)
        }
    }

    // handling product and service image upload
    const handleImage = (event, index, type) => {
        try {

            const { name } = event.target
            const collection = pluralize(type)
            const file = event.target.files[0]

            if (file) {
                const newDataArray = [...application.state[collection]]
                newDataArray[index][name] = file
                newDataArray[index]["oldImage"] = file.name
                application.dispatch({ [collection]: newDataArray })
            }

        } catch (error) {
            toast(error.message)
        }
    }

    // handling file change, ie card image, logo or vcard
    const handleFileChange = (event) => {
        try {

            const { name } = event.target
            const file = event.target.files[0]
            if (file)
                application.dispatch({ [name]: file })

        } catch (error) {
            toast(error)
        }
    }

    // form submitting function
    const submitForm = async (event) => {
        try {

            // preventing default form submit
            event.preventDefault()

            // errors
            const errors = []

            // validating form fields
            if (string.isEmpty(application.state.firstName)) {
                errors.push("")
                application.dispatch({ firstNameError: "required" })
            } else if (string.isEmpty(application.state.qr)) {
                errors.push("")
                application.dispatch({ firstNameError: "required" })
            }

            // checking if there is no error occured
            if (array.isEmpty(errors)) {

                deleteOldFiles()
                const imagesUploaded = await uploadImage()

                if (imagesUploaded) {

                    const card = {
                        qr: application.state.qr,
                        title: application.state.title,
                        color: application.state.color,
                        abouts: application.state.abouts,
                        folderName: application.state.folderName,
                        lastName: string.removeExtraWhitespace(application.state.lastName)?.toLowerCase(),
                        firstName: string.removeExtraWhitespace(application.state.firstName)?.toLowerCase(),
                        logo: object.isValid(application.state.logo) ? application.state.logo.name : application.state.logo,
                        image: object.isValid(application.state.image) ? application.state.image.name : application.state.image,
                        address: {
                            region: application.state.region,
                            country: application.state.country,
                            location: application.state.location,
                        },
                        contacts: {
                            vcard: object.isValid(application.state.vcard) ? application.state.vcard.name : application.state.vcard,
                            email: application.state.email,
                            facebook: application.state.facebook,
                            linkedin: application.state.linkedin,
                            whatsapp: application.state.whatsapp,
                            instagram: application.state.instagram,
                            phoneNumber: application.state.phoneNumber,
                        },
                        products: !array.isEmpty(application.state.products) ? application.state.products.map((product) => {
                            if (object.isValid(product.image))
                                return { ...product, image: product.image.name }
                            else
                                return product
                        }) : [],
                        services: !array.isEmpty(application.state.services) ? application.state.services.map((service) => {
                            if (object.isValid(service.image))
                                return { ...service, image: service.image.name }
                            else
                                return service
                        }) : [],
                    }

                    const options = {
                        loading: true,
                        method: application.state.edit ? "PUT" : "POST",
                        route: api + (application.state.edit ? "update" : "create"),
                        body: {
                            schema: "card",
                            documentData: card,
                            newDocumentData: { $set: card },
                            condition: { _id: application.state.id },
                        }
                    }

                    const response = await application.createOrUpdate(options)

                    if (response.success) {
                        props.history.push({
                            pathname: `/card/${application.state.qr}`
                        })
                        toast(`card has been ${application.state.edit ? "updated" : "created"} successful`)
                        application.unMount()
                        application.dispatch({ services: [], products: [], abouts: [] })
                    }
                    else
                        toast(response.message)
                }
            }

        } catch (error) {
            toast(error.message)
        }
    }

    // function for uploading images
    async function uploadImage() {
        try {

            const images = []
            const folderName = application.state.folderName

            if (object.isValid(application.state.image))
                images.push(application.state.image)

            if (object.isValid(application.state.logo))
                images.push(application.state.logo)

            if (object.isValid(application.state.vcard))
                images.push(application.state.vcard)

            for (const service of application.state.services)
                if (object.isValid(service.image))
                    images.push(service.image)

            for (const product of application.state.products)
                if (object.isValid(product.image))
                    images.push(product.image)

            if (!array.isEmpty(images)) {

                const fileName = images.length > 1 ? "files" : "file"

                const formData = new FormData()

                for (const image of images)
                    formData.append(fileName, image)

                formData.append("body", JSON.stringify({ folderName }))

                application.dispatch({ loading: true })
                const response = await (await fetch(`${serverURL}/${api}upload-${fileName}`, {
                    method: "POST",
                    mode: "cors",
                    body: formData,
                })).json()
                application.dispatch({ loading: false })

                if (response.success)
                    return true
                else {
                    toast(response.message)
                    return false
                }
            }
            else {
                toast("no images provided")
                return true
            }


        } catch (error) {
            application.dispatch({ loading: false })
            toast(error.message)
            return false
        }
    }

    async function deleteOldFiles() {
        try {

            if (application.state.edit) {

                const images = []
                const folderName = application.state.folderName

                if (string.isNotEmpty(application.state.oldImage) && object.isValid(application.state.image))
                    images.push(application.state.oldImage)

                if (string.isNotEmpty(application.state.oldLogo) && object.isValid(application.state.logo))
                    images.push(application.state.oldLogo)

                if (string.isNotEmpty(application.state.oldVcard) && object.isValid(application.state.vcard))
                    images.push(application.state.oldVcard)

                for (const service of application.state.services)
                    if (string.isNotEmpty(service.oldImage) && object.isValid(service.image))
                        images.push(service.oldImage)

                for (const product of application.state.products)
                    if (string.isNotEmpty(product.oldImage) && object.isValid(product.image))
                        images.push(product.oldImage)

                if (!array.isEmpty(images)) {

                    const condition = JSON.stringify(images.map((image) => ({ folderName, fileName: image })))

                    const response = await application.readOrDelete({
                        mode: "cors",
                        loading: true,
                        disabled: false,
                        method: "DELETE",
                        route: api + 'delete-files',
                        parameters: `queries=${condition}`
                    })

                    if (response.success)
                        return true
                    else {
                        return false
                    }
                }
            }

        } catch (error) {
            toast(error.message)
        }
    }

    return (
        <div className="row">
            <div className="col s12 m8 l7">
                <div className="card">
                    <CardTitle title={`${application.state.edit ? "edit" : "new"} card`} />
                    <div className="card-content">
                        <form action="#" onSubmit={submitForm}>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="firstName"
                                        label="firstName"
                                        placeholder="enter firstname"
                                        value={application.state.firstName}
                                        error={application.state.firstNameError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="lastName"
                                        label="lastName"
                                        placeholder="enter lastname"
                                        value={application.state.lastName}
                                        error={application.state.lastNameError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <Input
                                        type="text"
                                        name="title"
                                        label="title"
                                        placeholder="enter title"
                                        value={application.state.title}
                                        error={application.state.titleError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="file"
                                        name="image"
                                        label="image"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        error={application.state.imageError}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="file"
                                        name="logo"
                                        label="logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        error={application.state.logoError}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="number"
                                        name="phoneNumber"
                                        label="phone Number"
                                        placeholder="enter phone Number"
                                        value={application.state.phoneNumber}
                                        error={application.state.phoneNumberError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="email"
                                        name="email"
                                        label="email"
                                        placeholder="enter email address"
                                        value={application.state.email}
                                        error={application.state.emailError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="number"
                                        name="whatsapp"
                                        label="whatsapp Number"
                                        placeholder="enter whatsapp Number"
                                        value={application.state.whatsapp}
                                        error={application.state.whatsappError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="instagram"
                                        label="instagram username"
                                        placeholder="enter instagram username"
                                        value={application.state.instagram}
                                        error={application.state.instagramError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="facebook"
                                        label="facebook"
                                        placeholder="enter facebook"
                                        value={application.state.facebook}
                                        error={application.state.facebookError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="linkedin"
                                        label="linkedin"
                                        placeholder="enter linkedin"
                                        value={application.state.linkedin}
                                        error={application.state.linkedinError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="file"
                                        name="vcard"
                                        label="vcard"
                                        accept="text/vcard,.vcf"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="color"
                                        name="color"
                                        label="primary color"
                                        placeholder="enter color"
                                        value={application.state.color}
                                        error={application.state.colorError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="country"
                                        label="country"
                                        placeholder="enter country"
                                        value={application.state.country}
                                        error={application.state.countryError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                                <div className="col s12 m6 l6">
                                    <Input
                                        type="text"
                                        name="region"
                                        label="region"
                                        placeholder="enter region"
                                        value={application.state.region}
                                        error={application.state.regionError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <Input
                                        type="text"
                                        name="location"
                                        label="location"
                                        placeholder="enter location"
                                        value={application.state.location}
                                        error={application.state.locationError}
                                        onChange={application.handleInputChange}
                                    />
                                </div>
                            </div>
                            {
                                application.state.services.map((service, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="row">
                                                <div className="col s12 m6 l6">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        label="service name"
                                                        placeholder="enter service name"
                                                        value={service.name}
                                                        onChange={(e) => handleFormChange(e, index, "service")}
                                                    />
                                                </div>
                                                <div className="col s12 m6 l6">
                                                    <Input
                                                        type="file"
                                                        name="image"
                                                        accept="image/*"
                                                        label="service image"
                                                        onChange={(e) => handleImage(e, index, "service")}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col s12">
                                                    <Textarea
                                                        name="description"
                                                        label="service description"
                                                        value={service.description}
                                                        onChange={(e) => handleFormChange(e, index, "service")}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="action-button">
                                                <Link to="#" className="button error" data-tooltip="Remove Service" onClick={() => removeForm(index, "service")}>
                                                    <i className="material-icons">delete</i>
                                                </Link>
                                            </div>
                                            <div className="divider"></div>
                                        </React.Fragment>
                                    )
                                })
                            }
                            {
                                application.state.products.map((product, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="row">
                                                <div className="col s12 m6 l6">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        label="product name"
                                                        placeholder="enter product name"
                                                        value={product.name}
                                                        onChange={(e) => handleFormChange(e, index, "product")}
                                                    />
                                                </div>
                                                <div className="col s12 m6 l6">
                                                    <Input
                                                        type="file"
                                                        name="image"
                                                        accept="image/*"
                                                        label="product image"
                                                        onChange={(e) => handleImage(e, index, "product")}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col s12">
                                                    <Textarea
                                                        name="description"
                                                        label="product description"
                                                        value={product.description}
                                                        onChange={(e) => handleFormChange(e, index, "product")}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="action-button">
                                                <Link to="#" className="button error" data-tooltip="Remove product" onClick={() => removeForm(index, "product")}>
                                                    <i className="material-icons">delete</i>
                                                </Link>
                                            </div>
                                            <div className="divider"></div>
                                        </React.Fragment>
                                    )
                                })
                            }
                            {
                                application.state.abouts.map((about, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="row">
                                                <div className="col s12">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        label="about name"
                                                        placeholder="enter about name"
                                                        value={about.name}
                                                        onChange={(e) => handleFormChange(e, index, "about")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12">
                                                    <Textarea
                                                        name="description"
                                                        label="about description"
                                                        value={about.description}
                                                        placeholder="enter about description"
                                                        onChange={(e) => handleFormChange(e, index, "about")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="action-button">
                                                <Link to="#" className="button error" data-tooltip="Remove about" onClick={() => removeForm(index, "about")}>
                                                    <i className="material-icons">delete</i>
                                                </Link>
                                            </div>
                                            <div className="divider"></div>
                                        </React.Fragment>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="col s4 left-align">
                                    <Link to="#" className="guest-link" onClick={() => addForm("service")}>
                                        Add Service
                                    </Link>
                                </div>
                                <div className="col s4 center">
                                    <Link to="#" className="guest-link" onClick={() => addForm("about")}>
                                        Add About
                                    </Link>
                                </div>
                                <div className="col s4 right-align">
                                    <Link to="#" className="guest-link" onClick={() => addForm("product")}>
                                        Add Product
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 center">
                                    <Button
                                        loading={application.state.loading}
                                        disabled={application.state.disabled}
                                        title={application.state.edit ? "update card" : "create card"}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col s12 m4 l5">
                <div className="card">
                    <CardTitle title="preview" />
                    <div className="card-content">
                        <CardPreview />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CardForm