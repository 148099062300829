// dependencies
import initialState from "./initial-state"

// reducer
const reducer = (state, action) => {
    try {

        const newStateValues = { ...state, ...action }
        const stateKeys = Object.getOwnPropertyNames(action)
        const type = stateKeys[0]

        // checking wether reducer action types contains "Error" keyword so that we can change disabled state to true
        if (type.includes("Error"))
            // copy the previous state then update only the received keywords and set disabled to true
            return { ...newStateValues, disabled: true }

        // checking for action that are'nt allowed to change disabled state
        if ((type === "authenticated") || (type === "disabled") || (type === "loading"))
            // copy the previous state then update only the received keywords
            return newStateValues

        if ((type === "unMount"))
            // return initial state
            return { ...initialState, authenticated: state.authenticated ? true : false }

        // copy the previous state then update only the received keywords, set disabled to true and update "Error" field with empty string
        return { ...newStateValues, [`${type}Error`]: "", disabled: false }

    } catch (error) {
        console.log(`Reducer error ${error.message}`)
        return state
    }
}

export default reducer