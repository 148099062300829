// dependencies
import { string } from "fast-web-kit"
import M from "materialize-css"

window.Buffer = window.Buffer || require("buffer").Buffer;
const crypto = require('crypto-browserify')

// environment variables
const environment = process.env
const project_stage = environment.NODE_ENV
const initialization_vector = Buffer.from(environment.REACT_APP_INITIALIZATION_VECTOR || "2d52550dc714656D", "utf8")
const encryption_key = Buffer.from(environment.REACT_APP_ENCRYPTION_KEY || "abcdefghijkLMNOPQrstuvwXyZAFRIKA", "utf8")
const encryption_type = environment.REACT_APP_ENCRYPTION_TYPE
const encryption_algorithm = environment.REACT_APP_ENCRYPTION_ALGORITHM
export const has_enable_encryption = environment.REACT_APP_ENABLE_ENCRYPTION === "true" ? true : false


// application name
export const applicationName = "Digital Edge"

export const encodeText = (text) => {
    try {

        const encoder = new TextEncoder()
        return encoder.encode(text)

    } catch (error) {
        console.log(error.message)
        return text
    }
}

// message toasting
export const toast = (message) => new M.Toast({ html: string.capitalize(message), displayLength: 5000 })

// backend api endpoint
export const api = "api/"

// setting page title
export const setPageTitle = (title) => document.title = string.toTitleCase(title)

// application domain name
const domain = "https://i-card.co"

const port = 2004

/* creating and exporting backend server URL */
export const serverURL = project_stage === "development" ? `http://172.20.10.8:${port}` : `${domain}`

// socket url
export const socketURL = project_stage === "development" ? `http://172.20.10.8:${port}` : `${domain}:${port}`

// decryption function
export function decrypt(data) {
    try {
        const decipher = crypto.createDecipheriv(encryption_algorithm, encryption_key, initialization_vector)
        const decrypted = JSON.parse(decipher.update(data.payload, encryption_type, "utf8") + decipher.final("utf8"))
        return decrypted
    } catch (error) {
        console.log(error.message)
        localStorage.clear()
        return { success: false, message: error.message }
    }
}

// encryption function
export function encrypt(data) {
    try {
        const cipher = crypto.createCipheriv(encryption_algorithm, encryption_key, initialization_vector)
        const payload = cipher.update(JSON.stringify(data), "utf8", encryption_type) + cipher.final(encryption_type)
        return { payload }
    } catch (error) {
        console.log(error.message)
        localStorage.clear()
        return { payload: error.message }
    }
}

// local storage operations
export const storage = {
    clear: () => localStorage.clear(),
    retrieve: (key) => {

        const payload = localStorage.getItem(key)

        if (payload) {
            const data = decrypt({ payload: JSON.parse(payload) })
            return data
        }
        return payload

    },
    store: (key, data) => localStorage.setItem(key, JSON.stringify(encrypt(data).payload)),
    remove: (key) => localStorage.removeItem(key)
}

/* creating and exporting  function for getting specific local storage data information */
export const getInfo = (key, info) => {

    // retrieving data from local storage
    const data = storage.retrieve(key)

    // verifying data is available
    if (data)

        // verifying data information has been provided
        if (info?.trim())
            // returning requsted info
            return data[info]

        // data info is not provided
        else
            // returning all data information NOTE: same as storage.retrieve() function
            return data

    else
        return null

}

/* user */
export const user = getInfo("user", "")