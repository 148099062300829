import App from './app'
import React from 'react'
import "./sass/toast.css"
import "./icons/index.css"
import "./sass/light.scss"
import ReactDOM from 'react-dom/client'
import { ApplicationProvider } from "./providers"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApplicationProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApplicationProvider>

);