// dependencies
import React from "react"
import { CardTitle } from "../../components/card"
import { ApplicationContext } from "../../context"
import { Button, Input } from "../../components/input"
import { string, email, array } from "fast-web-kit"
import { api, setPageTitle, toast } from "../../helpers"

// login memorized function component
const Login = React.memo(() => {

    // application context
    const { application } = React.useContext(ApplicationContext)

    // component mounting
    React.useEffect(() => {
        setPageTitle("login")

        // component unmounting
        return () => application.unMount()

        // eslint-disable-next-line
    }, [])

    // validate form
    const validateForm = async (event) => {
        try {

            // prevent default form submit
            event.preventDefault()

            const errors = []

            if (string.isEmpty(application.state.email)) {
                errors.push("")
                application.dispatch({ emailError: "email address is required" })
            }
            else if (!email.isValid(application.state.email)) {
                errors.push("")
                application.dispatch({ emailError: "email address is not valid" })
            }

            if (string.isEmpty(application.state.password)) {
                errors.push("")
                application.dispatch({ passwordError: "password is required" })
            }
            else if (string.getLength(application.state.password) < 8) {
                errors.push("")
                application.dispatch({ passwordError: "password must have atleast 8 characters" })
            }

            if (array.isEmpty(errors)) {

                const options = {
                    loading: true,
                    method: "POST",
                    route: api + "authenticate",
                    body: {
                        select: {},
                        schema: "user",
                        joinForeignKeys: false,
                        fieldWithEncryption: "password",
                        valueToCompareWithEncryption: application.state.password,
                        condition: { email: application.state.email.trim().toLowerCase() },
                    },
                }

                const response = await application.createOrUpdate(options)

                if (response.success)
                    application.authenticate("login", response.message)
                else
                    toast(response.message)

            }

        } catch (error) {
            toast(error.message)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col s12 m10 l6 offset-l3 offset-m1">
                    <form action="#" onSubmit={validateForm}>
                        <div className="card">
                            <CardTitle title="login page" />
                            <div className="card-content">
                                <div className="row">
                                    <div className="col s12">
                                        <Input
                                            type="text"
                                            icon="email"
                                            label="email"
                                            name="email"
                                            value={application.state.email}
                                            error={application.state.emailError}
                                            placeholder="enter your email address"
                                            onChange={application.handleInputChange}
                                        />
                                    </div>
                                    <div className="col s12">
                                        <Input
                                            icon="lock"
                                            type="password"
                                            name="password"
                                            label="password"
                                            value={application.state.password}
                                            error={application.state.passwordError}
                                            onChange={application.handleInputChange}
                                            placeholder="enter your account password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-action">
                                <div className="row">
                                    <div className="col s12 center">
                                        <Button
                                            icon="login"
                                            title="login"
                                            loading={application.state.loading}
                                            disabled={application.state.disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
})

export default Login