import React from "react"
import { api, setPageTitle, toast } from "../../helpers"
import { ApplicationContext } from "../../context"
import CardPreview from "./preview"
import { string } from "fast-web-kit"

const CardView = React.memo((props) => {

    const { application } = React.useContext(ApplicationContext)

    React.useEffect(() => {
        onMount()
        setPageTitle("card view")
        // eslint-disable-next-line
    }, [])

    async function onMount() {
        try {

            const { user } = props.match.params

            if (user) {

                const select = JSON.stringify({ createdAt: 0, updatedAt: 0, __v: 0 })
                const joinForeignKeys = false
                const condition = JSON.stringify({ qr: user })
                const parameters = `schema=card&condition=${condition}&select=${select}&joinForeignKeys=${joinForeignKeys}`
                const options = {
                    parameters,
                    method: "GET",
                    loading: true,
                    disabled: false,
                    route: api + "read"
                }
                const response = await application.readOrDelete(options)

                if (response.success) {
                    document.title = string.toTitleCase(response.message.firstName)
                    const card = response.message
                    application.dispatch({
                        edit: true,
                        qr: card?.qr,
                        id: card._id,
                        logo: card.logo,
                        image: card.image,
                        title: card.title,
                        color: card.color,
                        abouts: card.abouts,
                        services: card.services,
                        products: card.products,
                        lastName: card.lastName,
                        firstName: card.firstName,
                        email: card.contacts.email,
                        vcard: card.contacts.vcard,
                        folderName: card.folderName,
                        region: card.address.region,
                        country: card.address.country,
                        location: card.address.location,
                        facebook: card.contacts.facebook,
                        linkedin: card.contacts.linkedin,
                        whatsapp: card.contacts.whatsapp,
                        instagram: card.contacts.instagram,
                        phoneNumber: card.contacts.phoneNumber
                    })

                    // document.querySelector("body").style.backgroundColor = "#fff"
                }
                // else {
                //     props.history.goBack()
                //     toast(response.message)
                // }
            }
            // else
            // props.history.goBack()

        } catch (error) {
            toast(error.message)
        }
    }

    return (
        <>
            <div className="row" style={{ backgroundColor: "#fff" }}>
                <div className="col s12 m10 l6 offset-m1 offset-l3">
                    <CardPreview />
                </div>
            </div>
        </>
    )

})

export default CardView