// dependencies
import React from "react"
import { string } from "fast-web-kit"

export const CardTitle = React.memo((props) => {

    return (
        <div className="card-title center">
            {string.toTitleCase(props.title)}
        </div>
    )

})