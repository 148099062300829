import { api, decrypt, encrypt, getInfo, has_enable_encryption, serverURL, storage, toast } from "."


class Application {

    constructor(state, dispatch) {
        this.state = state
        this.dispatch = dispatch
        this.serverURL = serverURL
        this.user = getInfo("user")
        this.headers = {
            "Content-Type": "application/json",
            "token": this.user ? encrypt(this.user._id).payload : ""
        }
    }

    // component unmounting
    unMount = () => this.dispatch({ unMount: { ...this.state } })

    // handling input change
    handleInputChange = (event) => {
        try {

            // get HTML element name and value from event target
            const { name, value } = event.target

            // update application state
            this.dispatch({ [name]: value })

        } catch (error) {
            toast(error.message)
        }
    }

    // function for POST (creating) and PUT (updating) resource(s) to the server
    createOrUpdate = async (options) => {
        try {

            // enable loading if necessary
            this.dispatch({ loading: options.loading })

            // make a post or put request to the server
            const response = await (await fetch(`${this.serverURL}/${options.route}`, {
                method: options.method,
                mode: "cors",
                body: JSON.stringify(has_enable_encryption ? encrypt(options.body) : options.body),
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ loading: false })

            if (has_enable_encryption)
                return decrypt(response)

            // returning response
            return response

        } catch (error) {

            // disable loading
            this.dispatch({ loading: false })
            return { success: false, message: error.message }

        }
    }

    // function for GET (reading) and DELETE (deleting) resource(s) to the server
    readOrDelete = async (options) => {
        try {

            // enable loading if necessary
            this.dispatch({ loading: options.loading })
            this.dispatch({ disabled: options.disabled ? true : false })

            // make a get or delete request to the server
            let response = await (await fetch(`${this.serverURL}/${options.route}?${has_enable_encryption ? `payload=${encrypt(options.parameters).payload}` : options.parameters}`, {
                method: options.method,
                mode: "cors",
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ loading: false })
            this.dispatch({ disabled: false })

            // returning response
            if (has_enable_encryption) {
                response = decrypt(response)
                if (response.success && (options.method === "GET")) {
                    // this.dispatch({ [options]})
                }
            }

            return response

        } catch (error) {

            // disable loading
            this.dispatch({ loading: false })
            this.dispatch({ disabled: false })

            return { success: false, message: error.message }

        }
    }

    // user authentication login and logout
    authenticate = (action, data) => {
        try {

            // checking authentication action
            if (action === 'login' && data) {

                // storing user data in local storage
                storage.store("user", data)

                // updating authenticated state to true
                this.dispatch({ authenticated: true })
                window.location.href = "/"
            }
            else {
                storage.remove("user")
                this.dispatch({ authenticated: false })
                window.location.href = "/"
            }

        } catch (error) {

            // remove user from local storage
            storage.remove("user")
            toast(error.message)

        }
    }

    // function for retrieving user from local storage and authenticating
    retrieveUserAndAuthenticate = async () => {
        try {

            // retrieving user
            const user = storage.retrieve("user")

            // verifying user data exist in local storage
            if (user) {
                this.dispatch({ authenticated: true })
                // updating authenticated state to true
                const joinForeignKeys = false
                const select = JSON.stringify({})
                const condition = JSON.stringify({ _id: user._id })
                const options = {
                    method: "GET",
                    loading: false,
                    disabled: false,
                    route: api + "read",
                    parameters: `schema=user&condition=${condition}&select=${select}&joinForeignKeys=${joinForeignKeys}`
                }

                const response = await this.readOrDelete(options)

                if (response.success) {
                    storage.store("user", response.message)
                }
                else {
                    localStorage.clear()
                    window.location.reload()
                }

            }

            else {
                // updating authenticated state to false
                this.dispatch({ authenticated: false })
            }

        } catch (error) {

            // updating authenticated state to false
            this.dispatch({ authenticated: false })
            toast(error.message)

        }
    }

}

export default Application